<template>
  <v-container id="links" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <base-material-card icon="mdi-account-outline">
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              Create promocode
              <span class="body-1">— Complete form for start make money</span>
            </div>
          </template>

          <v-form ref="form" lazy-validation>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    class="purple-input"
                    v-model="promocodeName"
                    :rules="promocodeNameRules"
                    label="Promocode name"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-select
                    :items="bonusTypes"
                    v-model="bonusTypeId"
                    label="Choose bonus type"
                  />
                </v-col>

                <v-col cols="12" md="12">
                  <v-text-field
                    label="Copy &amp; use this PROMOCODE"
                    v-model="getPromoCode"
                    class="purple-input"
                    @click="copyPromoCode"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="copySnackbar" timeout="2000">
      Copied!
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="copySnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "PromocodesDashboard",

  data() {
    return {
      promocodeName: "",
      bonusTypeId: null,
      subId: "",
      promocodeMaxLength: 16,

      loading: false,
      copySnackbar: false,
    };
  },
  created() {
    this.$store.dispatch("webmasterSettings/getSettings");
  },
  computed: {
    ...mapState({
      bonusTypes: (state) =>
        state.webmasterSettings.bonusTypes.map((bonusType) => {
          return { value: bonusType.id, text: bonusType.name };
        }),
    }),
    promocodeNameRules() {
      let v = this.promocodeName;
      let maxLength = this.promocodeMaxLength;
      const rules = [
        (v) => !!v || "Promocode name is required",
        (v) => /^[a-zA-Z0-9]+$/.test(v) || "Only letters and digits",
        (v) =>
          this.getPromoCode.length < maxLength ||
          "Max length of Promocode is " + maxLength,
      ];
      return rules;
    },
    getPromoCodePrefix() {
      return this.$auth.user().id + "U" + this.bonusTypeId + "B";
    },
    getPromoCode() {
      if (!this.promocodeName.length || !this.bonusTypeId) {
        return "";
      }
      let code = this.promocodeName.toUpperCase();
      code = code.replace(/[^A-Z0-9]/g, "");
      code = this.getPromoCodePrefix + code;
      code = code.slice(0, this.promocodeMaxLength);
      return code;
    },
  },

  methods: {
    copyPromoCode(e) {
      e.target.select();
      let promoCode = this.getPromoCode;
      if (!promoCode.length) {
        return;
      }
      navigator.clipboard.writeText(promoCode);
      this.copySnackbar = true;
    },
  },
};
</script>
