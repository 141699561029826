<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-twitter"
          title="Gamers"
          :value="registrations"
          sub-icon="mdi-clock"
          sub-text="Just Updated"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-poll"
          title="Balance"
          :value="balance"
          sub-icon="mdi-tag"
          sub-text="Current balance"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="success"
          icon="mdi-store"
          title="Total Credits"
          :value="credits"
          sub-icon="mdi-calendar"
          sub-text="Last 7 Days"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="orange"
          icon="mdi-sofa"
          title="Rating Position"
          :value="rating"
          sub-icon="mdi-flag"
          sub-icon-color="orange"
          sub-text="Rating depends on income and registrations"
        />
      </v-col>

      <v-col
        cols="12"
      >
        <div
          class="font-weight-light mt-1"
          style="font-size: 25px"
        >
          News
        </div>
      </v-col>

      <v-col
        sm="12"
        md="4"
        v-for="item in news"
        v-bind:key="item.id"
      >
        <base-material-card
          color="transparent"
          image
          hover-reveal
        >
          <template v-slot:image>
            <v-img
              v-if="item.image"
              :src="item.image"
            />
          </template>

          <v-card-title class="justify-center font-weight-light">
            {{ item.title }}
          </v-card-title>

          <v-card-text class="body-1 text-center mb-3 font-weight-light grey--text">
            {{ item.excerpt }}
          </v-card-text>

          <v-card-text v-html="item.body" class="body-1 text-center mb-3 font-weight-light grey--text"/>
        </base-material-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex';
  export default {
    name: 'DashboardDashboard',

    mounted() {
      this.$store.dispatch("webmasterDashboard/getDashboard")
    },

    computed: {
      balance(){
        if(!this.balanceAmount){
          return 0
        }
        return this.balanceAmount + ' ' + this.baseCurrency
      },
      credits(){
        if(!this.totalCredits){
          return 0
        }
        return this.totalCredits + ' ' + this.baseCurrency
      },
      registrations(){
        if(!this.dailyRegistrations){
          return 0
        }
        return '+' + this.dailyRegistrations
      },
      rating(){
        if(!this.dashboardRating){
          return 'N/A'
        }
        return '#' + this.dashboardRating
      },
      ...mapState({
        baseCurrency: state => state.webmasterDashboard.baseCurrency,
        balanceAmount: state => state.webmasterDashboard.balance,
        totalCredits: state => state.webmasterDashboard.totalCredits,
        dailyRegistrations: state => state.webmasterDashboard.dailyRegistrations,
        dashboardRating: state => state.webmasterDashboard.rating,
        news: state => state.webmasterDashboard.news,
      }),
    }
  }
</script>
