<template>
  <v-container id="links" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <base-material-card icon="mdi-account-outline">
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              Create link
              <span class="body-1">— Complete form for start make money</span>
            </div>
          </template>

          <v-form ref="form" lazy-validation>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field
                    class="purple-input"
                    v-model="linkName"
                    :rules="linkNameRules"
                    label="Link name"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    :items="sites"
                    v-model="siteId"
                    label="Choose sites"
                    @change="filterPrograms"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    :items="programs"
                    v-model="programId"
                    label="Choose programs"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field label="Sub ID" v-model="subId" class="purple-input" />
                </v-col>

                <v-col cols="12" md="12">
                  <v-text-field
                    label="Copy &amp; use this link"
                    v-model="getLinkCode"
                    class="purple-input"
                    @click="copyLinkCode"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="copySnackbar" timeout="2000">
      Copied!
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="copySnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "LinksDashboard",

  data() {
    return {
      linkName: "",
      sites: [],
      siteId: null,
      programs: [],
      programId: null,
      subId: "",

      loading: false,

      linkNameRules: [
        (v) => !!v || "Link name is required",
        (v) => /^[a-zA-Z0-9-]+$/.test(v) || "Only letters, digits and -",
      ],
      copySnackbar: false,
    };
  },
  created() {
    this.fetchSettings();
  },
  computed: {
    ...mapState({
      tdsDomain: (state) => state.webmasterSettings.tdsDomain,
    }),
    getLinkCode() {
      if (!this.linkName || !this.siteId || !this.programId) {
        return "";
      }

      const linkCode = this.$auth.user().id + "p" + this.programId + "_" + this.linkName;
      let link = this.tdsDomain + "/" + linkCode;
      if (this.subId) {
        link += "?sub=" + this.subId;
      }
      return link;
    },
  },

  methods: {
    fetchSettings() {
      this.loading = true;
      this.$store
        .dispatch("webmasterSettings/getSettings")
        .then(() => {
          this.loading = false;
          this.sites = this.$store.getters["webmasterSettings/getSiteList"];
          this.filterPrograms();
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });

      this.$store.dispatch("webmasterSettings/getTdsDomain");
    },
    filterPrograms() {
      const settings = this.$store.state.webmasterSettings;
      if (!settings || !settings.programs) {
        this.programs = [];
        return;
      }

      let programs = Object.values(settings.programs).filter((item) => {
        return item.site_id == this.siteId;
      });
      programs = programs.map((item) => {
        return { text: item.title, value: item.id };
      });
      this.programs = programs;
    },

    copyLinkCode(e) {
      e.target.select();
      let linkCode = this.getLinkCode;
      if (!linkCode.length) {
        return;
      }
      navigator.clipboard.writeText(linkCode);
      this.copySnackbar = true;
    },
  },
};
</script>
