<template>
  <v-container
    id="support"
    fluid
    tag="section"
  >
    <v-row justify="center">
    </v-row>

  </v-container>
</template>

<script>
  export default {
    name: 'SupportDashboard',

    data () {
      return {

      }
    },

    computed: {

    },

    methods: {

    },
  }
</script>
