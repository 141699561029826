<template>
  <v-container id="stats" fluid tag="section">
    <base-v-component heading="Stats" subheading="" link="components/data-tables" />

    <base-material-card color="indigo" icon="mdi-vuetify" inline class="px-5 py-3">
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">Stats</div>
      </template>

      <input type="date" v-model="dateFrom" @change="fetchData()" />
      <input type="date" v-model="dateTo" @change="fetchData()" />

      <v-divider class="mt-3" />

      <v-col cols="12">
        <v-card class="my-0">
          <v-card-text>
            <base-material-tabs color="warning" v-model="activeTab" @change="fetchData">
              <v-tab v-for="(tab, i) in tabs" :key="i">
                {{ tab }}
              </v-tab>

              <v-tab-item>
                <v-card flat class="my-0">
                  <v-data-table
                    :headers="headers.gameStats"
                    :items="stats.gameStats"
                    :sort-by="['stats_date']"
                    :sort-desc="[true]"
                    multi-sort
                  />
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat class="my-0">
                  <v-data-table
                    :headers="headers.linkStats"
                    :items="stats.linkStats"
                    :sort-by="['stats_date']"
                    :sort-desc="[true]"
                    multi-sort
                  />
                </v-card>
              </v-tab-item>
            </base-material-tabs>
          </v-card-text>
        </v-card>
      </v-col>
    </base-material-card>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "StatsDashboard",

  data() {
    return {
      activeTab: 0,
      tabs: ["Game Stats", "Link Stats"],

      stats: {
        gameStats: [],
        linkStats: [],
      },
      loading: false,
      dateFrom: dayjs().format("YYYY-MM-01"),
      dateTo: dayjs().format("YYYY-MM-DD"),

      headers: {
        gameStats: [
          {
            text: "Date",
            value: "stats_date",
          },
          {
            text: "User ID",
            value: "site_user_id",
          },
          {
            text: "Link Code / Promocode",
            value: "link_code",
          },
          {
            text: "Registrations",
            value: "registrations",
          },
          {
            text: "FTD count",
            value: "first_deposit_count",
          },
          {
            text: "FTD sum",
            value: "first_deposit_sum",
          },
          {
            text: "Deposit count",
            value: "deposit_count",
          },
          {
            text: "Deposit sum",
            value: "deposit_sum",
          },
          {
            text: "Income",
            value: "income",
          },
          {
            text: "Webmaster Income",
            value: "webmaster_income",
          },
        ],
        linkStats: [
          {
            text: "Date",
            value: "stats_date",
          },
          {
            text: "Link Code / Promocode",
            value: "linkcode",
          },
          {
            text: "Registrations",
            value: "registrations",
          },
          {
            text: "FTD count",
            value: "first_deposit_count",
          },
          {
            text: "FTD sum",
            value: "first_deposit_sum",
          },
          {
            text: "Deposit count",
            value: "deposit_count",
          },
          {
            text: "Deposit sum",
            value: "deposit_sum",
          },
          {
            text: "Income",
            value: "income",
          },
          {
            text: "Webmaster Income",
            value: "webmaster_income",
          },
        ],
      },
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      let storeName = "webmasterStats/getStats";
      let parser = "getGameStats";
      switch (this.activeTab) {
        case 1:
          storeName = "webmasterLinks/getLinks";
          parser = "getLinksStats";
          break;
        case 0:
        default:
          storeName = "webmasterStats/getStats";
          parser = "getGameStats";
          break;
      }

      const params = {
        date_from: dayjs(this.dateFrom).format("YYYY-MM-DD"),
        date_to: dayjs(this.dateTo).format("YYYY-MM-DD"),
      };

      this.$store
        .dispatch(storeName, params)
        .then(() => {
          this.loading = false;
          this[parser]();
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },

    getGameStats() {
      const data = this.$store.getters["webmasterStats/getStats"];
      if (!data.length) {
        this.stats.gameStats = [];
      }
      const stats = Object.values(data).map((item) => {
        return {
          stats_date: item.stats_date,
          site_user_id: "# " + item.site_user_id,
          link_code: item.link ? item.link.link_code : "N/A",
          registrations: item.has_registration | 0,
          first_deposit_count: item.has_first_deposit ? 1 : 0,
          first_deposit_sum: item.first_deposit_sum,
          deposit_count: item.deposit_count,
          deposit_sum: item.deposit_sum,
          tds_hits: item.tds_hits,
          tds_hosts: item.tds_hosts,
          income: item.income,
          webmaster_income: item.webmaster_income,
        };
      });
      this.stats.gameStats = stats;
    },
    getLinksStats() {
      const data = this.$store.getters["webmasterLinks/getLinks"];
      if (!data.length) {
        this.stats.linkStats = [];
      }
      const links = Object.values(data).map((item) => {
        return {
          stats_date: item.stats_date,
          linkcode: item.link ? item.link.link_code : "N/A",
          registrations: item.registrations_count,
          first_deposit_count: item.first_deposit_count,
          first_deposit_sum: item.first_deposit_sum,
          deposit_count: item.deposit_count,
          deposit_sum: item.deposit_sum,
          tds_hits: item.tds_hits,
          tds_hosts: item.tds_hosts,
          income: item.income,
          webmaster_income: item.webmaster_income,
        };
      });
      this.stats.linkStats = links;
    },
  },
};
</script>
