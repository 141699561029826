<template>
  <v-container id="payment_details" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <base-material-card icon="mdi-account-outline">
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              Edit Payment Method
              <span class="body-1">— Complete form to get withdrawals</span>
            </div>
          </template>

          <base-material-alert v-if="message" :color="messageColor" dark>
            {{ message }}
          </base-material-alert>

          <v-form @submit.prevent="onSubmit">
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="4">
                  <v-select
                    :items="paymentMethods"
                    v-model="paymentMethodId"
                    label="Choose payment method"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field v-model="requisite" class="purple-input" label="Wallet" />
                </v-col>

                <v-col cols="12" class="text-right">
                  <v-btn type="submit" color="success" class="mr-0">
                    Update Payment Method
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "PaymentDetailsDashboard",

  data() {
    return {
      selectedPaymentMethodId: null,
      selectedRequisite: "",
      message: "",
      messageColor: "success",
    };
  },
  created() {
    this.$store.dispatch("webmasterPaymentDetails/getPaymentDetails");
  },
  computed: {
    paymentMethodId: {
      get() {
        const paymentDetails = this.paymentDetails
        if (!paymentDetails || !paymentDetails.payment_system_id) {
          return null
        }
        this.selectedPaymentMethodId = paymentDetails.payment_system_id;
        return paymentDetails.payment_system_id;
      },
      set(value) {
        this.selectedPaymentMethodId = value;
      },
    },
    requisite: {
      get() {
        const paymentDetails = this.paymentDetails
        if (!paymentDetails || !paymentDetails.requisite) {
          return "";
        }
        this.selectedRequisite = paymentDetails.requisite.wallet;
        return this.paymentDetails.requisite.wallet;
      },
      set(value) {
        this.selectedRequisite = value;
      },
    },

    paymentMethods() {
      return this.paymentSystems.map((ps) => {
        return {
          text: ps.name + " (" + ps.currency.code + ")",
          value: ps.id,
        };
      });
    },
    ...mapState({
      paymentSystems: (state) => state.webmasterPaymentDetails.paymentSystems,
      paymentDetails: (state) => state.webmasterPaymentDetails.paymentDetails,
    }),
  },

  methods: {
    onSubmit() {
      const params = {
        payment_system_id: this.selectedPaymentMethodId,
        requisite: this.selectedRequisite,
      };
      this.$store
        .dispatch("webmasterPaymentDetails/storePaymentDetails", params)
        .then((res) => {
          this.messageColor = 'success'
          this.message = 'Success saved'
        })
    },
  },
};
</script>
