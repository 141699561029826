<template>
  <v-container id="transactions" fluid tag="section">
    <base-v-component
      heading="Transactions"
      subheading=""
      link="components/data-tables"
    />

    <base-material-card color="indigo" icon="mdi-vuetify" inline class="px-5 py-3">
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">Transactions</div>
      </template>

      <input type="date" v-model="dateFrom" @change="fetchData()" />
      <input type="date" v-model="dateTo" @change="fetchData()" />

      <v-divider class="mt-3" />

      <v-data-table
        :headers="headers"
        :items="transactions"
        :sort-by="['stats_date']"
        :sort-desc="[true]"
        multi-sort
      />
    </base-material-card>
  </v-container>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "TransactionsDashboard",

  data() {
    return {
      transactions: [],
      loading: false,
      dateFrom: dayjs().format("YYYY-MM-01"),
      dateTo: dayjs().format("YYYY-MM-DD"),

      headers: [
        {
          text: "Date",
          value: "stats_date",
        },
        {
          text: "Amount",
          value: "amount",
        },
        {
          text: "Operation",
          value: "operation",
        },
        {
          text: "Status",
          value: "status",
        },
      ]

    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const params = {
        date_from: dayjs(this.dateFrom).format("YYYY-MM-DD"),
        date_to: dayjs(this.dateTo).format("YYYY-MM-DD"),
      };

      this.$store
        .dispatch("webmasterTransactions/getTransactions", params)
        .then(() => {
          this.loading = false;
          this.getTransactions();
        })
        .catch((e) => {
          this.loading = false;
          console.log(e);
        });
    },
    getTransactions() {
      const data = this.$store.getters["webmasterTransactions/getTransactions"];
      if (!data.length) {
        this.transactions = [];
      }
      const transactions = Object.values(data).map((item) => {
        return {
          stats_date: item.stats_date,
          amount: item.amount + item.currency.code,
          operation: item.operation,
          status: item.status,
        };
      });

      this.transactions = transactions;
    },
  },

};
</script>
